.scroll-area {
  margin-bottom: 20px;
  padding-right: 3px;
  
  @media (min-width: 993px) {
  
    overflow-y: auto;
    max-height: 60%;
  }
  
  &::-webkit-scrollbar-button {
    background-image: none;
    background-repeat: no-repeat;
    width: 5px;
    height: 0
  }

  &::-webkit-scrollbar-track {
    background-color: #999
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #ecedee
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ecedee
  }

  &::-webkit-resizer {
    background-image: none;
    background-repeat: no-repeat;
    width: 4px;
    height: 0
  }

  &::-webkit-scrollbar {
    width: 4px
  }
}

.slide-model {
  .scroll-area {
    max-height: 80%;
  }
}


.model-info {
  position: relative;
  width: 100%;
  padding: 15px 15px 30px 15px;
  background-color: rgb(0, 0, 0);
  color: #fff;
  z-index: 20;
  
  @media (min-width: 993px) {
    display: flex;
    flex-direction: column;
    padding: 15px;
    max-width: 490px;
    max-height: calc(100% - 200px);
    background-color: rgba(0, 0, 0, 0.61);
    overflow: hidden;
  }
  
  &--title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 20px;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px;
    
    @media (min-width: 993px) {
      font-size: 25px;
    }
    
    @media (min-width: 1366px) {
      font-size: 45px;
      margin-bottom: 25px;
    }
    
    .model {
      margin-right: 5px;
    }
    
    span {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.26px;
      padding: 3px;
      background: #ed1c24;
      white-space: nowrap;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      text-transform: capitalize;
      
      @media (min-width: 993px) {
        font-size: 22px;
        padding: 5px 15px;
      }
      
      @media (min-width: 1366px) {
        font-size: 26px;
      }
    }
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    li {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: -0.22px;
      
      @media (min-width: 993px) {
        font-size: 18px;
      }
      
      @media (min-width: 1366px) {
        font-size: 22px;
      }
    }
    
    strong, b {
      font-weight: 700;
    }
  }
  
  p {
    font-weight: 300;
    font-size: 16px;
    
    @media (min-width: 993px) {
      font-size: 22px;
      line-height: 25px;
    }
    
    strong {
      font-size: 19px;
      font-weight: 700;
      
      @media (min-width: 993px) {
        font-size: 22px;
      }
    }
  }
  
  .btn {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
    
    @media (min-width: 993px) {
      font-size: 25px;
      max-width: none;
      margin-top: auto;
    }
  }
  
  .show-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    background: #ed1c24;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.26px;
    text-decoration: none;
    
    @media (min-width: 993px) {
      font-size: 20px;
      max-width: none;
      width: 100%;
      margin-top: auto;
      margin-bottom: 10px;
    }
  }
}

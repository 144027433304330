@charset "utf-8";

@import 'foundation';
@include foundation-reveal;
@import 'settings';
@import 'motion-ui';
@include motion-ui-transitions;
@include motion-ui-animations;

:root {
  --main-font-family: Agenda, sans-serif;
  --hover-color: #ed1c24;
}

html {
  touch-action: manipulation;
}

@import "modules/mixins";
@import "modules/buttons";
@import "modules/header";
@import "modules/footer";
@import "modules/slick";
@import "modules/slick-theme";
@import "modules/form";
@import "modules/material-panel";
@import "modules/model-info";
@import "modules/breadcrumbs";
@import "pages/home";
@import "pages/about";
@import "pages/catalog";
@import "pages/contact-us";
@import "pages/model";
@import "pages/project";
@import "pages/railings";
@import "pages/faq";

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: #fff;
  font-size: 16px;
  font-family: var(--main-font-family);
}

.main {
  @media (min-width: 768px) {
    display: flex;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
  }
  
  .content-wrapper {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
}

body:not(.railings-page):not(.faq-page) {
  .main {
    .content-wrapper {
      @media (min-width: 992px) {
        overflow: hidden;
      }
    }
  }
}

.grecaptcha-badge {
  opacity: 0;
}

.toggle-btn {
  display: flex;
  align-items: center;
  fill: #fff;
  color: #fff;
  
  svg {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    transition: 0.4s ease-in-out;
  }
  
  span {
    font-size: 22px;
    white-space: nowrap;
    transition: 0.4s ease-in-out;
  }
}

#toggle-view {
  position: absolute;
  display: none;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  height: 48px;
  min-width: 165px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  
  @media (min-width: 993px) {
    display: block;
  }
  
  @media (min-width: 1200px) {
    bottom: 130px;
  }
}

.project-page {
  #toggle-view {
    @media (max-width: 1799px) {
      bottom: 10px;
    }
  }
  
  .toggle-btn {
    @media (min-width: 1800px) {
      fill: #000;
      color: #000;
    }
  }
  
  &.full-view {
    .toggle-btn {
      @media (min-width: 1800px) {
        fill: #fff;
        color: #fff;
      }
    }
  }
}

.header, .breadcrumbs-wrap, .model-info, .model-page .slick-slider {
 transition: 0.4s ease-in-out;
}

#exit_fullscreen {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.full-view {
  overflow: hidden;
  
  #exit_fullscreen {
    position: relative;
    opacity: 1;
    visibility: visible;
  }
  
  #fullscreen {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  
  .header, .breadcrumbs-wrap {
    top: -100%;
  }

 .footer {
   position: absolute;
   bottom: -100%;
 }
  
  .model-info {
    opacity: 0;
    visibility: hidden;
  }
  
  .materials-panel {
    bottom: -100%;
  }
  
  &.model-page .slick-slider {
    height: 100vh!important;
    width: 100vw!important;
  }
  
  .toggle-btn {
    fill: #fff;
    color: #fff;
  }
}

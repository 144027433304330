@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.5.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

html.is-reveal-open {
  position: fixed;
  width: 100%;
  overflow-y: hidden; }
  html.is-reveal-open.zf-has-scroll {
    overflow-y: scroll; }
  html.is-reveal-open body {
    overflow-y: hidden; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: auto; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 100%;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.99875em) {
    .reveal {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      min-height: 100%;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-ccw-1turn; }

@keyframes spin-ccw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

:root {
  --main-font-family: Agenda, sans-serif;
  --hover-color: #ed1c24; }

html {
  touch-action: manipulation; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 2px solid #fff;
  transition: 0.4s ease-in-out;
  color: #fff;
  line-height: 1; }
  .btn:hover {
    background: #fff;
    color: #000; }

.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  z-index: 1000; }
  .header .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: black; }
    @media (min-width: 992px) {
      .header .wrapper {
        padding: 15px;
        background-color: rgba(0, 0, 0, 0.6); } }
    @media (min-width: 1600px) {
      .header .wrapper {
        padding: 5px 130px 5px 70px; } }
  .header .logo {
    display: block;
    max-width: 100px; }
    @media (min-width: 1600px) {
      .header .logo {
        max-width: 180px; } }

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .menu .menu-item {
    margin: 15px;
    float: left; }
    @media (min-width: 992px) {
      .menu .menu-item {
        margin-top: 0;
        margin-bottom: 0; } }
    @media (min-width: 1600px) {
      .menu .menu-item {
        margin-left: 25px;
        margin-right: 25px; } }
    .menu .menu-item.current-menu-item a {
      text-decoration: underline; }
  .menu a {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    transition: .4s ease-in-out;
    will-change: color; }
    @media (min-width: 1600px) {
      .menu a {
        font-size: 30px; } }
    .menu a:hover, .menu a:focus {
      outline: none;
      color: var(--hover-color); }

@media (min-width: 992px) {
  #nav-main-mobile {
    display: none; } }

@media (max-width: 991px) {
  .nav-main {
    position: fixed;
    display: none;
    top: 52px;
    left: 0;
    width: 100%;
    background: #0a0a0a;
    height: calc(100vh - 52px);
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

@media (max-width: 991px) {
  .nav-main ul {
    display: flex;
    width: 100%;
    background: #0a0a0a;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42px;
  width: 46px;
  border: none;
  background-color: transparent;
  padding: 15px 10px; }
  @media (min-width: 992px) {
    .hamburger {
      display: none; } }
  .hamburger:focus {
    outline: none; }
  .hamburger span {
    display: block;
    width: 26px;
    height: 2px;
    background-color: #989898;
    transform-origin: 50% 50%;
    transition: 0.4s ease-in-out; }
    .hamburger span:last-of-type {
      margin-top: auto; }
  .hamburger.open span:first-of-type {
    transform: rotate(-45deg) translate(-4px, 3px); }
  .hamburger.open span:last-of-type {
    transform: rotate(45deg) translate(-3px, -3px); }

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  flex-shrink: 0;
  justify-content: space-between; }
  @media (min-width: 600px) {
    .footer {
      flex-direction: row; } }
  @media (min-width: 1800px) {
    .footer {
      padding: 10px 120px; } }
  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media (min-width: 600px) {
      .footer-info {
        margin-top: 0;
        margin-bottom: 0; } }
    @media (min-width: 1200px) {
      .footer-info {
        display: block; } }
    .footer-info .info-item {
      display: inline-block;
      position: relative;
      color: #231f20;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 3.5px;
      padding-left: 10px;
      padding-right: 10px; }
      @media (min-width: 1600px) {
        .footer-info .info-item {
          font-size: 22px;
          padding-left: 20px;
          padding-right: 20px; } }
      .footer-info .info-item:first-of-type {
        padding-left: 0; }
      .footer-info .info-item:last-of-type {
        padding-right: 0; }
      .footer-info .info-item + .info-item {
        margin-top: 10px; }
        @media (min-width: 600px) {
          .footer-info .info-item + .info-item {
            margin-top: 0; } }
        @media (min-width: 1600px) {
          .footer-info .info-item + .info-item:before {
            content: '•';
            color: #231f20;
            position: relative;
            left: -20px; } }
      .footer-info .info-item .info-phone {
        color: var(--hover-color);
        text-decoration: none; }
  .footer .logo {
    display: block;
    max-width: 150px; }

.social-links-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .social-links-menu li:not(:first-of-type) {
    margin-left: 10px; }
  .social-links-menu li:not(:last-of-type) {
    margin-right: 10px; }
  .social-links-menu a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #959595;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;
    text-decoration: none; }
    @media (min-width: 1600px) {
      .social-links-menu a {
        width: 40px;
        height: 40px; } }
    .social-links-menu a svg {
      fill: #fff;
      height: 20px;
      transition: .4s ease-in-out; }
      @media (min-width: 1600px) {
        .social-links-menu a svg {
          height: 30px; } }
  .social-links-menu .facebook:hover svg {
    fill: #3b5998; }
  .social-links-menu .linkedin:hover svg {
    fill: #0077b5; }
  .social-links-menu .instagram:hover svg {
    fill: #405de6; }
  .social-links-menu .pinterest:hover svg {
    fill: #bd081c; }
  .social-links-menu .twitter:hover svg {
    fill: #1da1f2; }
  .social-links-menu .youtube:hover svg {
    fill: #ff0000; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
  transition: opacity 0.4s ease-in-out; }
  .slick-slider.slick-initialized {
    opacity: 1; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slider-text-align-right {
  align-items: flex-end; }

.slider-text-align-left {
  align-items: flex-start; }

.slider-text-align-center {
  align-items: center; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 25px;
  width: 25px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border-radius: 50%;
  border: none;
  outline: none;
  z-index: 100; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    color: #fff; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    display: block;
    height: 25px;
    width: 25px;
    line-height: 1;
    background-color: #000;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 50%;
    overflow: hidden;
    z-index: 100; }
  @media (min-width: 768px) {
    .slick-prev,
    .slick-next {
      width: 50px;
      height: 50px; }
      .slick-prev:before,
      .slick-next:before {
        width: 50px;
        height: 50px; } }

.slick-prev {
  left: 5px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 5px; }
  .slick-prev:before {
    content: '';
    background-image: url("../images/arrow_left.svg"); }
    [dir="rtl"] .slick-prev:before {
      background-image: url("../images/arrow_right.svg"); }

.slick-next {
  right: 5px; }
  [dir="rtl"] .slick-next {
    left: 5px;
    right: auto; }
  .slick-next:before {
    content: '';
    background-image: url("../images/arrow_right.svg"); }
    [dir="rtl"] .slick-next:before {
      background-image: url("../images/arrow_left.svg"); }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: 25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 100; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #ff0000;
      opacity: 0.75; }

.slick-slider {
  background-color: #000; }
  @media (min-width: 992px) {
    .slick-slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10; }
      .slick-slider .slide {
        width: 100%;
        height: 100%; } }
  .slick-slider.slick-dotted {
    margin-bottom: 0; }
  .slick-slider img {
    width: 100%;
    display: none; }
  .slick-slider .slick-track {
    display: flex;
    align-items: center; }
  .slick-slider .slide {
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center; }
  .slick-slider .slide-orientation-landscape {
    background-size: cover; }
  .slick-slider .slide-orientation-portrait {
    background-size: contain; }
  @media (max-width: 992px) {
    .slick-slider .slide {
      background-image: none !important; }
    .slick-slider img {
      display: block; }
    .slick-slider .slick-dots {
      position: relative; } }

.form-wrapper {
  display: inline-block;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff; }
  .form-wrapper textarea {
    resize: none;
    width: 100%; }
  .form-wrapper label {
    align-items: center;
    justify-content: space-between;
    font-size: 18px; }
    @media (min-width: 414px) {
      .form-wrapper label {
        display: flex; } }
    .form-wrapper label input {
      height: 30px;
      width: 100%; }
      @media (min-width: 414px) {
        .form-wrapper label input {
          width: 250px; } }
  .form-wrapper [type="submit"] {
    padding: 5px 30px;
    background: transparent;
    text-transform: uppercase;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    transition: .4s; }
    .form-wrapper [type="submit"]:hover {
      background-color: #fff;
      color: #000; }
  .form-wrapper .view-map {
    color: #fff;
    font-size: 24px;
    text-transform: capitalize;
    text-decoration: underline; }

form p {
  margin-top: 5px;
  margin-bottom: 5px; }

form .your-message {
  margin-top: 20px;
  margin-bottom: 5px; }

.materials-panel {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(35, 31, 32, 0.31);
  z-index: 22; }
  .materials-panel h3 {
    color: #231f20;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: -0.28px;
    margin: 0;
    white-space: nowrap; }
    @media (min-width: 1366px) {
      .materials-panel h3 {
        font-size: 19px; } }
    @media (max-width: 1199px) {
      .materials-panel h3 {
        width: 100%; } }
  .materials-panel.desktop-visible {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1199px) {
      .materials-panel.desktop-visible {
        display: none; } }
  @media (min-width: 1200px) {
    .materials-panel.desktop-hidden {
      display: none !important; } }

.material-block.flow {
  display: flex;
  flex-direction: column; }
  .material-block.flow h3 {
    margin-right: 10px; }
  .material-block.flow .wrap {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 80px;
    flex-grow: 0;
    margin: 0 -5px; }
  .material-block.flow .material-item {
    flex: 0 1 20px; }

.material-block .wrap {
  display: block;
  margin-left: -5px;
  margin-right: -5px; }

.material-block--beech .material-item .tooltip {
  transform: translate3d(0, -102%, 0); }

.material-block--options {
  display: inline-block; }
  .material-block--options h3 {
    margin-right: 10px; }
  .material-block--options .wrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0; }
  .material-block--options .material-item {
    width: 20px;
    height: 20px; }
    .material-block--options .material-item img {
      width: 20px;
      height: 20px; }
    .material-block--options .material-item .tooltip {
      transform: translate3d(-90%, -102%, 0); }

.divider {
  width: 1px;
  height: 100%;
  background: #231f20;
  flex: 1 0 auto;
  max-width: 1px;
  margin-left: 15px;
  margin-right: 15px; }

.material-item {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 5px;
  border: 1px solid #c5c5c5;
  background-size: cover;
  cursor: pointer; }
  .material-item img {
    display: block;
    width: 30px;
    height: 30px; }
  @media (min-width: 1200px) {
    .material-item img {
      display: block; } }

.material-options {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer; }
  .material-options > span {
    color: #231f20;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis; }

.material-item, .material-options {
  position: relative; }
  .material-item .tooltip, .material-options .tooltip {
    position: absolute;
    display: none;
    padding: 3px;
    top: 0;
    left: 0;
    opacity: 0;
    border: 1px solid #000;
    background-color: #fff;
    visibility: hidden;
    transform: translate3d(-50px, -102%, 0);
    transition: .4s ease-in-out;
    z-index: 100; }
    @media (min-width: 768px) {
      .material-item .tooltip, .material-options .tooltip {
        transform: translate3d(-100px, -102%, 0); } }
    .material-item .tooltip p, .material-options .tooltip p {
      font-size: 16px;
      margin-top: 3px;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis; }
      @media (min-width: 1366px) {
        .material-item .tooltip p, .material-options .tooltip p {
          font-size: 18px; } }
    .material-item .tooltip span, .material-options .tooltip span {
      display: block;
      width: 100px;
      height: 100px;
      overflow: hidden;
      background-size: cover;
      margin-left: auto;
      margin-right: auto; }
      @media (min-width: 768px) {
        .material-item .tooltip span, .material-options .tooltip span {
          width: 200px;
          height: 200px; } }
  .material-item:hover .tooltip, .material-options:hover .tooltip {
    position: absolute;
    display: inline-block;
    opacity: 1;
    visibility: visible; }

.scroll-area {
  margin-bottom: 20px;
  padding-right: 3px; }
  @media (min-width: 993px) {
    .scroll-area {
      overflow-y: auto;
      max-height: 60%; } }
  .scroll-area::-webkit-scrollbar-button {
    background-image: none;
    background-repeat: no-repeat;
    width: 5px;
    height: 0; }
  .scroll-area::-webkit-scrollbar-track {
    background-color: #999; }
  .scroll-area::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #ecedee; }
  .scroll-area::-webkit-scrollbar-thumb:hover {
    background-color: #ecedee; }
  .scroll-area::-webkit-resizer {
    background-image: none;
    background-repeat: no-repeat;
    width: 4px;
    height: 0; }
  .scroll-area::-webkit-scrollbar {
    width: 4px; }

.slide-model .scroll-area {
  max-height: 80%; }

.model-info {
  position: relative;
  width: 100%;
  padding: 15px 15px 30px 15px;
  background-color: black;
  color: #fff;
  z-index: 20; }
  @media (min-width: 993px) {
    .model-info {
      display: flex;
      flex-direction: column;
      padding: 15px;
      max-width: 490px;
      max-height: calc(100% - 200px);
      background-color: rgba(0, 0, 0, 0.61);
      overflow: hidden; } }
  .model-info--title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 20px;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px; }
    @media (min-width: 993px) {
      .model-info--title {
        font-size: 25px; } }
    @media (min-width: 1366px) {
      .model-info--title {
        font-size: 45px;
        margin-bottom: 25px; } }
    .model-info--title .model {
      margin-right: 5px; }
    .model-info--title span {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.26px;
      padding: 3px;
      background: #ed1c24;
      white-space: nowrap;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      text-transform: capitalize; }
      @media (min-width: 993px) {
        .model-info--title span {
          font-size: 22px;
          padding: 5px 15px; } }
      @media (min-width: 1366px) {
        .model-info--title span {
          font-size: 26px; } }
  .model-info ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .model-info ul li {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: -0.22px; }
      @media (min-width: 993px) {
        .model-info ul li {
          font-size: 18px; } }
      @media (min-width: 1366px) {
        .model-info ul li {
          font-size: 22px; } }
    .model-info ul strong, .model-info ul b {
      font-weight: 700; }
  .model-info p {
    font-weight: 300;
    font-size: 16px; }
    @media (min-width: 993px) {
      .model-info p {
        font-size: 22px;
        line-height: 25px; } }
    .model-info p strong {
      font-size: 19px;
      font-weight: 700; }
      @media (min-width: 993px) {
        .model-info p strong {
          font-size: 22px; } }
  .model-info .btn {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px; }
    @media (min-width: 993px) {
      .model-info .btn {
        font-size: 25px;
        max-width: none;
        margin-top: auto; } }
  .model-info .show-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    background: #ed1c24;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.26px;
    text-decoration: none; }
    @media (min-width: 993px) {
      .model-info .show-btn {
        font-size: 20px;
        max-width: none;
        width: 100%;
        margin-top: auto;
        margin-bottom: 10px; } }

.breadcrumbs-wrap {
  position: fixed;
  background: #000;
  left: 0;
  top: 52px;
  width: 100%;
  padding-left: 15px;
  z-index: 200; }
  @media (min-width: 992px) {
    .breadcrumbs-wrap {
      background: rgba(0, 0, 0, 0.6);
      top: 61px; } }
  @media (min-width: 1600px) {
    .breadcrumbs-wrap {
      top: 66px;
      padding-left: 70px; } }

.breadcrumbs {
  list-style: none;
  font-size: 24px;
  padding: 0;
  margin: 10px 0; }
  .breadcrumbs li {
    display: inline-block;
    color: #fff; }
    .breadcrumbs li + li:before {
      padding: 10px;
      color: inherit;
      content: "|"; }
    .breadcrumbs li a {
      text-decoration: none;
      color: inherit; }

.home .main {
  display: flex;
  height: 100vh;
  flex-direction: column; }
  .home .main .content-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; }

.home .slide-caption {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  max-width: 490px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.6); }
  @media (min-width: 600px) {
    .home .slide-caption {
      font-size: 30px;
      padding: 15px 100px 15px 20px; } }
  @media (min-width: 992px) {
    .home .slide-caption {
      position: absolute;
      right: 0;
      top: 55%; } }
  @media (min-width: 1600px) {
    .home .slide-caption {
      font-size: 38px; } }

@media (max-width: 991px) {
  .home .slick-dots {
    bottom: 0; } }

@media (max-width: 991px) {
  .home .slick-prev, .home .slick-next {
    top: 35%; } }

.slick-list, .slick-track, .slick-slide > div {
  height: 100%; }

.about-page .content-wrapper {
  display: flex; }

.about-page .about-wrap, .about-page .content {
  width: 100%;
  display: flex; }

.about-page .about-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 15px 50px; }

.about-page .article-content {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  max-width: 700px;
  font-size: 24px; }
  @media (min-width: 768px) {
    .about-page .article-content {
      padding: 30px; } }
  .about-page .article-content h1 {
    font-size: 30px; }

@media (min-width: 992px) {
  .about-page .slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    padding: 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20; } }

.about-page .slide .model-info {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  max-width: 700px;
  font-size: 24px; }

@media (min-width: 992px) {
  .catalog-page .header .wrapper {
    background: transparent; } }

@media (min-width: 992px) {
  .catalog-page .breadcrumbs-wrap {
    background: transparent; } }

.catalog-page .content-wrapper {
  display: flex;
  position: relative;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%; }
  @media (min-width: 1600px) {
    .catalog-page .content-wrapper {
      padding-top: 133px; } }
  .catalog-page .content-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2; }
  .catalog-page .content-wrapper .content {
    position: relative;
    width: 100%;
    z-index: 3; }

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto; }
  @media (min-height: 800px) and (min-width: 1600px) {
    .items.vertical {
      max-width: 60vw; } }
  .items.vertical .item:not(.links-wrap) {
    min-height: 250px; }
    @media (min-height: 800px) {
      .items.vertical .item:not(.links-wrap) {
        width: 450px; } }
    @media (max-height: 799px) and (min-width: 1600px) {
      .items.vertical .item:not(.links-wrap) {
        width: 450px; } }
  .items.vertical .item.links-wrap {
    width: 100%;
    max-width: none; }
  .items .item {
    position: relative;
    text-decoration: none;
    max-width: 450px;
    width: 100%;
    margin: 15px;
    overflow: hidden; }
    @media (min-width: 600px) {
      .items .item {
        width: calc(50% - 30px); } }
    @media (min-width: 992px) {
      .items .item {
        width: calc(33.333336% - 30px); } }
    .items .item:not(.links-wrap) {
      border: 2px solid #fff;
      padding-bottom: 50%; }
      @media (min-width: 600px) {
        .items .item:not(.links-wrap) {
          padding-bottom: 25%; } }
      @media (min-width: 992px) {
        .items .item:not(.links-wrap) {
          padding-bottom: 17.25%; } }
    .items .item .title {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      padding: 5px 15px;
      color: #fff;
      font-size: 30px;
      background-color: rgba(0, 0, 0, 0.46);
      text-decoration: none;
      z-index: 2;
      transition: 0.2s ease-in-out; }
      @media (min-width: 992px) {
        .items .item .title {
          font-size: 40px; } }
    .items .item img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
    .items .item-hover {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      flex-direction: column;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      visibility: hidden;
      opacity: 0;
      transition: 0.4s ease-in-out;
      z-index: -1; }
      .items .item-hover .post-title {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 10px; }
        @media (min-width: 1600px) {
          .items .item-hover .post-title {
            font-size: 50px;
            margin-bottom: 20px; } }
      .items .item-hover .post-content {
        display: none;
        max-width: 90%;
        padding-left: 5px;
        padding-right: 5px; }
        @media (min-width: 1280px) {
          .items .item-hover .post-content {
            display: block;
            font-size: 20px;
            line-height: 17px; } }
        @media (min-width: 1600px) {
          .items .item-hover .post-content {
            font-size: 25px;
            line-height: 22px; } }
    .items .item:hover .item-hover {
      opacity: 1;
      visibility: visible;
      z-index: 11; }
    .items .item:hover .title {
      margin-top: -100%; }
  .items .links-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .items .links-wrap a {
      width: 200px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 22px;
      margin-top: 10px;
      margin-bottom: 10px; }
      @media (min-width: 992px) {
        .items .links-wrap a {
          width: 250px;
          font-size: 30px; } }

.contact-page .content-wrapper {
  display: flex; }

.contact-page .contact-wrapper, .contact-page .content {
  width: 100%;
  display: flex; }

.contact-page .contact-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 30px 30px; }
  @media (min-width: 768px) {
    .contact-page .contact-wrapper {
      justify-content: flex-end;
      padding: 80px 50px 80px 50px; } }

.model-page {
  overflow: auto; }
  @media (min-width: 992px) {
    .model-page .main .content-wrapper {
      display: flex;
      position: relative;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 100%;
      overflow: hidden;
      align-items: center;
      justify-content: flex-end;
      padding-right: 60px; } }
  @media (min-width: 992px) {
    .model-page .main .content {
      height: 100%;
      min-height: 100vh; } }
  .model-page .slick-dots {
    bottom: 10px; }
  .model-page .materials-panel {
    flex-direction: column; }
    @media (max-width: 1199px) {
      .model-page .materials-panel {
        padding: 30px 50px; }
        .model-page .materials-panel .material-block {
          margin-bottom: 20px; }
        .model-page .materials-panel .wrap + .wrap {
          margin-top: 20px; } }
    @media (min-width: 1800px) {
      .model-page .materials-panel {
        position: absolute;
        flex-direction: row;
        min-height: 110px;
        flex-wrap: wrap; } }
    @media (max-width: 1199px) {
      .model-page .materials-panel .wrap {
        justify-content: center;
        text-align: center; } }
    @media (max-width: 1199px) {
      .model-page .materials-panel h3 {
        text-align: center;
        margin-bottom: 5px; } }
  @media (min-width: 1200px) {
    .model-page:not(.project-page) .materials-panel {
      position: absolute;
      flex-direction: row;
      min-height: 110px;
      flex-wrap: wrap;
      z-index: 1000; } }
  @media (min-width: 992px) {
    .model-page .model-wrapper, .model-page .slide {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      padding: 60px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20; } }
  .model-page .model-wrapper, .model-page .slider-text-align-right {
    align-items: flex-end; }
  @media (min-width: 992px) {
    .model-page .slick-slider {
      height: 100vh; } }
  @media (min-width: 1800px) {
    .model-page .slick-slider {
      height: calc(100% - 110px); } }
  @media (min-width: 1800px) {
    .model-page.w-panel .slick-slider {
      height: 100vh; } }
  @media (max-width: 991px) {
    .model-page .slick-prev, .model-page .slick-next {
      top: 30%; } }

#formModal {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none; }

.project-page {
  overflow: auto; }
  @media (min-width: 993px) {
    .project-page .main .content-wrapper {
      display: flex;
      position: relative;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 100%;
      overflow: hidden;
      align-items: center;
      padding-left: 60px; } }
  @media (min-width: 1600px) {
    .project-page .main .content-wrapper {
      padding-left: 120px; } }
  @media (max-width: 1500px) and (min-width: 992px) {
    .project-page .main .content-wrapper {
      min-height: 100vh; } }
  .project-page .slick-dots {
    bottom: 25px; }

.panel-title {
  display: flex;
  align-items: center;
  color: #231f20;
  font-size: 21px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase; }
  .panel-title:before {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    height: 4px;
    min-width: 235px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOsAAAAECAYAAABvGMiCAAABeElEQVRIS+1XW07DQAyccVpVICHxwU24APc/BXdASC1K1mjGmzapgAOgbRvF68d47MTaLRMg6qP7KgN4I14/iPOZuFysf59nHubZ8tQaY3lhtIXMmfG8kK35MpjlJzIXIkv/mUk+drvkfCDkJ7ktQUiXxOm0YoRiv6SzDUQeyWML57Aewo6yH+03Z9qOqQVVUx7IdAyhXLpPk/NpPQvDMUlOU0h3881ATl4vkR1PvlE45RuIjpthHLegeJDRdeKPDNoHVExTrDFixabQVozyTbaUrmKIUEyti0MQ+t38CPFmj7nzQ0YTl2s9Sr3HEx31pFWO3ivn6H2sWqoP6iOihbig1ui5XV/xVunmpd6oL+ZYvBXf38NeF8tO9VG+ymdM7LnKw1yudgmpvOvzEdTt/a7nVThyNWd9NQuVZzcP97HbOdnZNrNksnc5f5ix7bz1rH/cTHAM6xjWMaxjWMfOOnbWsbP+uiGOnXUcg69HzHEM9l+Mf3IM/gZHO6zT3rvN1gAAAABJRU5ErkJggg==) center no-repeat;
    background-size: cover; }
    @media (min-width: 768px) {
      .panel-title:before {
        content: ''; } }
  .panel-title:after {
    display: inline-block;
    position: relative;
    margin-left: 5px;
    height: 4px;
    min-width: 235px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOsAAAAECAYAAABvGMiCAAABa0lEQVRIS+1W20pDMRDc3RNsQfwDH3zw/7/BH/BPFARBKe3ZkZlNelrpi8+mh0IuMzuTTZbE3x+fYX/7XeLZHv3RBrYx82r//lPx1vjlGAEQn5+rCzMfenkVA4C55sjJzQNSnHOMoevkEy5sMD4owHHqCFcYt7R0Tmk+undhPVFzZzzDJajphkh5r75skdM9ZPFrzhQX7mlJuPx1HwNn1Ep5LeENRx3iXdriRvd9hRNG3FrTwJkDnl3TwRUzn5XHVDuYj84p7FiLA1ncOPtWvmofinMZr3swLMyBPK2V+76H0l5X7R1z0sp35WpdK27HwiPNT+V1De1Zkw9yjzozCI0zls4PjuQcgcol7jjuMXBmh4Ow1EMsKV4E4N9bjK/APTERxfUFiM/qKxmKh/xYAG/IWJDLG9Y+f2oNT63Ved7tYPs97PUBZi83a8xnsc5iVVHPYp3FOm/WebPOm3XerPMZPJ/Besb/l2fwD8NX9EHxWMtoAAAAAElFTkSuQmCC) center no-repeat;
    background-size: cover; }
    @media (min-width: 768px) {
      .panel-title:after {
        content: ''; } }

.project-page .materials-panel {
  flex-direction: column;
  align-items: center; }

.project-page .material-item {
  width: 130px;
  height: 130px; }

.project-page .model-info {
  max-height: 70%; }

@media (min-width: 992px) {
  .project-page .slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    padding: 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20; } }

.project-page .slide.slider-text-align-right {
  align-items: flex-end; }

.model-info--text {
  color: #ed1c24;
  font-size: 26px;
  font-weight: 700;
  text-align: center; }

.material-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }

.material-item--title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  min-height: 36px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 14.58px;
  text-transform: capitalize; }

.railings-page .content-wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .railings-page .content-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2; }
  .railings-page .content-wrapper .content {
    position: relative;
    width: 100%;
    z-index: 3; }

.railings-page .railings {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: baseline;
  justify-content: flex-start;
  padding: 70px 0 30px; }
  @media (min-width: 768px) {
    .railings-page .railings {
      padding: 100px 30px 60px; } }
  .railings-page .railings-item {
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    color: #fff; }
    @media (min-width: 414px) {
      .railings-page .railings-item {
        width: calc(50% - 30px); } }
    @media (min-width: 992px) {
      .railings-page .railings-item {
        width: calc(25% - 30px); } }
    @media (min-width: 1200px) {
      .railings-page .railings-item {
        width: calc(25% - 30px); } }
    @media (min-width: 1450px) {
      .railings-page .railings-item {
        width: calc(20% - 30px); } }
    .railings-page .railings-item p {
      font-size: 22px;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 5px; }
      .railings-page .railings-item p span {
        font-weight: 700; }

.railings-page .material-title {
  margin: 15px 45px;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase; }
  .railings-page .material-title + .railings {
    padding: 10px 30px; }

.railings-page .railing-image {
  display: block;
  position: relative;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #fff; }

.railings-page .railing-title {
  font-size: 25px; }

.faq-page .content-wrapper {
  display: flex;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; }

.faq-page .faq-wrap, .faq-page .content {
  width: 100%;
  display: flex; }

.faq-page .faq-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 15px 50px; }

.faq-page .article-content {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 100%;
  max-width: 1400px;
  font-size: 24px; }
  @media (min-width: 768px) {
    .faq-page .article-content {
      padding: 30px; } }
  .faq-page .article-content h1 {
    font-size: 30px; }

.faq-page .faq-list-wrap {
  overflow: auto; }

.faq-page .article-body {
  height: 100%; }

.panel {
  display: block;
  border-top: 1px solid #c2c2c2; }

.panel-header {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  cursor: pointer; }
  .panel-header .panel-toggle {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 30px;
    height: 31px;
    margin-right: 20px;
    transition: 0.4s ease-in-out; }
    .panel-header .panel-toggle:before {
      content: '+';
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      transition: 0.4s ease-in-out; }
  .panel-header h3 {
    margin: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 400; }
  .panel-header.opened .panel-toggle:before {
    content: '-'; }

* {
  box-sizing: border-box; }

body {
  padding: 0;
  margin: 0;
  background-color: #fff;
  font-size: 16px;
  font-family: var(--main-font-family); }

@media (min-width: 768px) {
  .main {
    display: flex;
    height: 100%;
    min-height: 100vh;
    flex-direction: column; } }

.main .content-wrapper {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0; }

@media (min-width: 992px) {
  body:not(.railings-page):not(.faq-page) .main .content-wrapper {
    overflow: hidden; } }

.grecaptcha-badge {
  opacity: 0; }

.toggle-btn {
  display: flex;
  align-items: center;
  fill: #fff;
  color: #fff; }
  .toggle-btn svg {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    transition: 0.4s ease-in-out; }
  .toggle-btn span {
    font-size: 22px;
    white-space: nowrap;
    transition: 0.4s ease-in-out; }

#toggle-view {
  position: absolute;
  display: none;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  height: 48px;
  min-width: 165px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000; }
  @media (min-width: 993px) {
    #toggle-view {
      display: block; } }
  @media (min-width: 1200px) {
    #toggle-view {
      bottom: 130px; } }

@media (max-width: 1799px) {
  .project-page #toggle-view {
    bottom: 10px; } }

@media (min-width: 1800px) {
  .project-page .toggle-btn {
    fill: #000;
    color: #000; } }

@media (min-width: 1800px) {
  .project-page.full-view .toggle-btn {
    fill: #fff;
    color: #fff; } }

.header, .breadcrumbs-wrap, .model-info, .model-page .slick-slider {
  transition: 0.4s ease-in-out; }

#exit_fullscreen {
  position: absolute;
  opacity: 0;
  visibility: hidden; }

.full-view {
  overflow: hidden; }
  .full-view #exit_fullscreen {
    position: relative;
    opacity: 1;
    visibility: visible; }
  .full-view #fullscreen {
    position: absolute;
    opacity: 0;
    visibility: hidden; }
  .full-view .header, .full-view .breadcrumbs-wrap {
    top: -100%; }
  .full-view .footer {
    position: absolute;
    bottom: -100%; }
  .full-view .model-info {
    opacity: 0;
    visibility: hidden; }
  .full-view .materials-panel {
    bottom: -100%; }
  .full-view.model-page .slick-slider {
    height: 100vh !important;
    width: 100vw !important; }
  .full-view .toggle-btn {
    fill: #fff;
    color: #fff; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 2px solid #fff;
  transition: 0.4s ease-in-out;
  color: #fff;
  line-height: 1;
  
  &:hover {
    background: #fff;
    color: #000;
  }
}

.form-wrapper {
  display: inline-block;
  padding: 20px;
  background-color: rgba(0,0,0, 0.6);
  color: #fff;
  
  textarea {
    resize: none;
    width: 100%;
  }
  
  label {
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    
    @media (min-width: 414px) {
      display: flex;
    }
    
    input {
      height: 30px;
      width: 100%;
      
      @media (min-width: 414px) {
        width: 250px;
      }
    }
  }
  
  [type="submit"] {
    padding: 5px 30px;
    background: transparent;
    text-transform: uppercase;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    transition: .4s;
    
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
  
  .view-map {
    color: #fff;
    font-size: 24px;
    text-transform: capitalize;
    text-decoration: underline;
  }
}

form {
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .your-message {
    margin-top: 20px;
    margin-bottom: 5px;
  }
}

.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  z-index: 1000;
  
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: rgb(0,0,0);
    
    @media (min-width: 992px) {
      padding: 15px;
      background-color: rgba(0,0,0, 0.6);
    }
    
    @media (min-width: 1600px) {
      padding: 5px 130px 5px 70px;
    }
  }
  
  .logo {
    display: block;
    max-width: 100px;
  
    @media (min-width: 1600px) {
      max-width: 180px;
    }
  }
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  .menu-item {
    margin: 15px;
    float: left;
  
    @media (min-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  
    @media (min-width: 1600px) {
      margin-left: 25px;
      margin-right: 25px;
    }
    
    &.current-menu-item {
      a {
        text-decoration: underline;
      }
    }
  }
  
  a {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    transition: .4s ease-in-out;
    will-change: color;
  
    @media (min-width: 1600px) {
      font-size: 30px;
    }
  
    &:hover, &:focus {
      outline: none;
      color: var(--hover-color);
    }
  }
}

#nav-main-mobile {
  @media (min-width: 992px) {
    display: none;
  }
}

.nav-main {
  @media (max-width: 991px) {
    position: fixed;
    display: none;
    top: 52px;
    left: 0;
    width: 100%;
    background: #0a0a0a;
    height: calc(100vh - 52px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  ul {
    @media (max-width: 991px) {
      display: flex;
      width: 100%;
      background: #0a0a0a;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      li {
      
      }
    }
  }
}

.hamburger{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42px;
  width: 46px;
  border: none;
  background-color: transparent;
  padding: 15px 10px;
  
  @media (min-width: 992px) {
    display: none;
  }
  
  &:focus {
    outline: none;
  }
  
  span {
    display: block;
    width: 26px;
    height: 2px;
    background-color: #989898;
    transform-origin: 50% 50%;
    transition: 0.4s ease-in-out;
    
    &:last-of-type {
      margin-top: auto;
    }
  }
  
  &.open {
    span:first-of-type {
      transform: rotate(-45deg) translate(-4px, 3px);
    }
    
    span:last-of-type {
      transform: rotate(45deg) translate(-3px, -3px);
    }
  }
}

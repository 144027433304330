.railings-page {
  .content-wrapper {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2;
    }

    .content {
      position: relative;
      width: 100%;
      z-index: 3;
    }
  }

  .railings {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: baseline;
    justify-content: flex-start;
    padding: 70px 0 30px;

    @media (min-width: 768px) {
      padding: 100px 30px 60px;
    }

    &-item {
      margin-bottom: 30px;
      margin-left: 15px;
      margin-right: 15px;
      width: calc(100% - 30px);
      color: #fff;

      @media (min-width: 414px) {
        width: calc(50% - 30px);
      }

      @media (min-width: 992px) {
        width: calc(25% - 30px);
      }

      @media (min-width: 1200px) {
        width: calc(25% - 30px);
      }

      @media (min-width: 1450px) {
        width: calc(20% - 30px);
      }

      p {
        font-size: 22px;
        font-weight: 300;
        margin-top: 5px;
        margin-bottom: 5px;

        span {
          font-weight: 700;
        }
      }
    }
  }

  .material-title {
    margin: 15px 45px;
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;

    & + .railings {
      padding: 10px 30px;
    }
  }

  .railing {
    &-image {
      display: block;
      position: relative;
      padding-bottom: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border: 2px solid #fff;
    }

    &-title {
      font-size: 25px;
    }
  }
}

.project-page {
  overflow: auto;
  
  .main {
    .content-wrapper {
      @media (min-width: 993px) {
        display: flex;
        position: relative;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 100%;
        overflow: hidden;
        align-items: center;
        padding-left: 60px;
      }
      
      @media (min-width: 1600px) {
        padding-left: 120px;
      }
      
      @media (max-width: 1500px) and (min-width: 992px){
        min-height: 100vh;
      }
    }
  }
  
  .slick-dots {
    bottom: 25px;
  }
}

.panel-title {
  display: flex;
  align-items: center;
  color: #231f20;
  font-size: 21px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  
  &:before {
    @media (min-width: 768px) {
      content: '';
    }
    
    display: inline-block;
    position: relative;
    margin-right: 5px;
    height: 4px;
    min-width: 235px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOsAAAAECAYAAABvGMiCAAABeElEQVRIS+1XW07DQAyccVpVICHxwU24APc/BXdASC1K1mjGmzapgAOgbRvF68d47MTaLRMg6qP7KgN4I14/iPOZuFysf59nHubZ8tQaY3lhtIXMmfG8kK35MpjlJzIXIkv/mUk+drvkfCDkJ7ktQUiXxOm0YoRiv6SzDUQeyWML57Aewo6yH+03Z9qOqQVVUx7IdAyhXLpPk/NpPQvDMUlOU0h3881ATl4vkR1PvlE45RuIjpthHLegeJDRdeKPDNoHVExTrDFixabQVozyTbaUrmKIUEyti0MQ+t38CPFmj7nzQ0YTl2s9Sr3HEx31pFWO3ivn6H2sWqoP6iOihbig1ui5XV/xVunmpd6oL+ZYvBXf38NeF8tO9VG+ymdM7LnKw1yudgmpvOvzEdTt/a7nVThyNWd9NQuVZzcP97HbOdnZNrNksnc5f5ix7bz1rH/cTHAM6xjWMaxjWMfOOnbWsbP+uiGOnXUcg69HzHEM9l+Mf3IM/gZHO6zT3rvN1gAAAABJRU5ErkJggg==) center no-repeat;
    background-size: cover;
  }
  
  &:after {
    @media (min-width: 768px) {
      content: '';
    }
    
    display: inline-block;
    position: relative;
    margin-left: 5px;
    height: 4px;
    min-width: 235px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOsAAAAECAYAAABvGMiCAAABa0lEQVRIS+1W20pDMRDc3RNsQfwDH3zw/7/BH/BPFARBKe3ZkZlNelrpi8+mh0IuMzuTTZbE3x+fYX/7XeLZHv3RBrYx82r//lPx1vjlGAEQn5+rCzMfenkVA4C55sjJzQNSnHOMoevkEy5sMD4owHHqCFcYt7R0Tmk+undhPVFzZzzDJajphkh5r75skdM9ZPFrzhQX7mlJuPx1HwNn1Ep5LeENRx3iXdriRvd9hRNG3FrTwJkDnl3TwRUzn5XHVDuYj84p7FiLA1ncOPtWvmofinMZr3swLMyBPK2V+76H0l5X7R1z0sp35WpdK27HwiPNT+V1De1Zkw9yjzozCI0zls4PjuQcgcol7jjuMXBmh4Ow1EMsKV4E4N9bjK/APTERxfUFiM/qKxmKh/xYAG/IWJDLG9Y+f2oNT63Ved7tYPs97PUBZi83a8xnsc5iVVHPYp3FOm/WebPOm3XerPMZPJ/Besb/l2fwD8NX9EHxWMtoAAAAAElFTkSuQmCC) center no-repeat;
    background-size: cover;
  }
}

.project-page {
  .materials-panel {
    flex-direction: column;
    align-items: center;
  }
  
  .material-item {
    width: 130px;
    height: 130px;
  }
  
  .model-info {
    max-height: 70%;
  }
  
  .slide {
    @media (min-width: 992px) {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      padding: 60px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
    }
  }
  
  .slide.slider-text-align-right {
    align-items: flex-end;
  }
}

.model-info--text {
  color: #ed1c24;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}

.material-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.material-item--title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  min-height: 36px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 14.58px;
  text-transform: capitalize;
}

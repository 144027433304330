.breadcrumbs-wrap {
  position: fixed;
  background: #000;
  left: 0;
  top: 52px;
  width: 100%;
  padding-left: 15px;
  z-index: 200;
  
  @media (min-width: 992px) {
    background: rgba(0, 0, 0, 0.6);
    top: 61px;
  }
  
  @media (min-width: 1600px) {
    top: 66px;
    padding-left: 70px;
  }
}

.breadcrumbs {
  list-style: none;
  font-size: 24px;
  padding: 0;
  margin: 10px 0;

  li {
    display: inline-block;
    color: #fff;

    + li:before {
      padding: 10px;
      color: inherit;
      content: "|";
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

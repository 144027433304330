.about-page {
  .content-wrapper {
    display: flex;
  }

  .about-wrap, .content {
    width: 100%;
    display: flex;
  }

  .about-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 80px 15px 50px;
  }

  .article-content {
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    max-width: 700px;
    font-size: 24px;

    @media (min-width: 768px) {
      padding: 30px;
    }

    h1 {
    font-size: 30px;
    }
  }

  .slide {
    @media (min-width: 992px) {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      padding: 60px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
    }

    .model-info {
      padding: 15px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      max-width: 700px;
      font-size: 24px;
    }
  }
}

.faq-page {
  .content-wrapper {
    display: flex;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  .faq-wrap, .content {
    width: 100%;
    display: flex;
  }
  
  .faq-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 80px 15px 50px;
  }
  
  .article-content {
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    width: 100%;
    max-width: 1400px;
    font-size: 24px;
    
    @media (min-width: 768px) {
      padding: 30px;
    }
    
    h1 {
      font-size: 30px;
    }
  }
  
  .faq-list-wrap {
    overflow: auto;
  }
  
  .article-body {
    height: 100%;
  }
}

.panel {
  display: block;
  border-top: 1px solid #c2c2c2;
}

.panel-header {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  cursor: pointer;
  
  .panel-toggle {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 30px;
    height: 31px;
    margin-right: 20px;
    transition: 0.4s ease-in-out;
    
    &:before {
      content: '+';
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      transition: 0.4s ease-in-out;
    }
  }
  
  h3 {
    margin: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
  }
  
  &.opened {
    .panel-toggle {
      &:before {
        content: '-';
      }
    }
  }
}

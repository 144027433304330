@import "../variables";

$materialPanelHeight: 110px;

.model-page {
  overflow: auto;
  
  .main {
    .content-wrapper {
      @media (min-width: 992px) {
        display: flex;
        position: relative;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: flex-end;
        padding-right: 60px;
      }
    }
    
    .content {
      @media (min-width: 992px) {
        height: 100%;
        min-height: 100vh;
      }
    }
  }
  
  .slick-dots {
    bottom: 10px;
  }
  
  .materials-panel {
    flex-direction: column;
    
    @media (max-width: $panelMobile) {
      padding: 30px 50px;

      .material-block {
        margin-bottom: 20px;
      }
  
      .wrap + .wrap {
        margin-top: 20px;
      }
    }
    
    @media (min-width: 1800px) {
      position: absolute;
      flex-direction: row;
      min-height: $materialPanelHeight;
      flex-wrap: wrap;
    }
    
    .wrap {
      @media (max-width: $panelMobile) {
        justify-content: center;
        text-align: center;
      }
    }

    h3 {
      @media (max-width: $panelMobile) {
        text-align: center;
        margin-bottom: 5px;
      }
    }
  }
  
  &:not(.project-page) {
    .materials-panel {
      @media (min-width: $panelDesktop) {
        position: absolute;
        flex-direction: row;
        min-height: $materialPanelHeight;
        flex-wrap: wrap;
        z-index: 1000;
      }
    }
  }
  
  .model-wrapper, .slide {
    @media (min-width: 992px) {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      padding: 60px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
    }
  }
  
  .model-wrapper, .slider-text-align-right {
    align-items: flex-end;
  }
  
  .slick-slider {
    @media (min-width: 992px) {
      height: 100vh;
    }
    
    @media (min-width: 1800px) {
      height: calc(100% - #{$materialPanelHeight});
    }
  }
  
  &.w-panel {
    .slick-slider {
      @media (min-width: 1800px) {
        height: 100vh;
      }
    }
  }
  
  .slick-prev, .slick-next {
    @media (max-width: 991px) {
      top: 30%;
    }
  }
}

#formModal {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
}

.catalog-page {
  .header {
    .wrapper {
      @media (min-width: 992px) {
        background: transparent;
      }
    }
  }
  
  .breadcrumbs-wrap {
    @media (min-width: 992px) {
      background: transparent;
    }
  }
  
  .content-wrapper {
    display: flex;
    position: relative;
    padding-top: 100px;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  
    @media (min-width: 1600px) {
      padding-top: 133px;
    }
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2;
    }
    
    .content {
      position: relative;
      width: 100%;
      z-index: 3;
    }
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  
  &.vertical {
    @media (min-height: 800px) and (min-width: 1600px) {
      max-width: 60vw;
    }
    
    .item {
      &:not(.links-wrap) {
        min-height: 250px;
  
        @media (min-height: 800px) {
          width: 450px;
        }
  
        @media (max-height: 799px) and (min-width: 1600px) {
          width: 450px;
        }
      }
      
      &.links-wrap {
        width: 100%;
        max-width: none;
      }
    }
  }
  
  .item {
    position: relative;
    text-decoration: none;
    max-width: 450px;
    width: 100%;
    margin: 15px;
    overflow: hidden;
    
    @media (min-width: 600px) {
      width: calc(50% - 30px);
    }
    
    @media (min-width: 992px) {
      width: calc(33.333336% - 30px);
    }
    
    &:not(.links-wrap) {
      border: 2px solid #fff;
      padding-bottom: 50%;
  
      @media (min-width: 600px) {
        padding-bottom: 25%;
      }
      
      @media (min-width: 992px) {
        padding-bottom: 17.25%;
      }
    }
    
    .title {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      padding: 5px 15px;
      color: #fff;
      font-size: 30px;
      background-color: rgba(0, 0, 0, 0.46);
      text-decoration: none;
      z-index: 2;
      transition: 0.2s ease-in-out;
  
      @media (min-width: 992px) {
        font-size: 40px;
      }
    }
    
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    
    &-hover {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      flex-direction: column;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      visibility: hidden;
      opacity: 0;
      transition: 0.4s ease-in-out;
      z-index: -1;
      
      .post-title {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 10px;
        
        @media (min-width: 1600px) {
          font-size: 50px;
          margin-bottom: 20px;
        }
      }
      
      .post-content {
        display: none;
        max-width: 90%;
        padding-left: 5px;
        padding-right: 5px;
  
        @media (min-width: 1280px) {
          display: block;
          font-size: 20px;
          line-height: 17px;
        }
  
        @media (min-width: 1600px) {
          font-size: 25px;
          line-height: 22px;
        }
      }
    }
    
    &:hover {
      .item-hover {
        opacity: 1;
        visibility: visible;
        z-index: 11;
      }
      
      .title {
        margin-top: -100%;
      }
    }
  }
  
  .links-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    a {
      width: 200px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 22px;
      margin-top: 10px;
      margin-bottom: 10px;
  
      @media (min-width: 992px) {
        width: 250px;
        font-size: 30px;
      }
    }
  }
}

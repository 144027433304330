.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  flex-shrink: 0;
  justify-content: space-between;
  
  @media (min-width: 600px) {
    flex-direction: row;
  }
  
  @media (min-width: 1800px) {
    padding: 10px 120px;
  }
  
  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    
    @media (min-width: 600px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  
    @media (min-width: 1200px) {
      display: block;
    }
    
    .info-item {
      display: inline-block;
      position: relative;
      color: #231f20;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 3.5px;
      padding-left: 10px;
      padding-right: 10px;
      
      @media (min-width: 1600px) {
        font-size: 22px;
        padding-left: 20px;
        padding-right: 20px;
      }
      
      &:first-of-type {
        padding-left: 0;
      }
      
      &:last-of-type {
        padding-right: 0;
      }
      
      + .info-item {
        margin-top: 10px;
        
        @media (min-width: 600px) {
          margin-top: 0;
        }
        
        @media (min-width: 1600px) {
          &:before {
            content: '•';
            color: #231f20;
            position: relative;
            left: -20px;
          }
        }
      }
      
      .info-phone {
        color: var(--hover-color);
        text-decoration: none;
      }
    }
  }
  
  .logo {
    display: block;
    max-width: 150px;
  }
}

.social-links-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  li {
    
    &:not(:first-of-type) {
      margin-left: 10px;
    }
    
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #959595;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;
    text-decoration: none;
    
    @media (min-width: 1600px) {
      width: 40px;
      height: 40px;
    }
    
    svg {
      fill: #fff;
      height: 20px;
      transition: .4s ease-in-out;
      
      @media (min-width: 1600px) {
        height: 30px;
      }
    }
  }
  
  .facebook {
    &:hover {
      svg {
        fill: #3b5998;
      }
    }
  }
  
  .linkedin {
    &:hover {
      svg {
        fill: #0077b5;
      }
    }
  }
  
  .instagram {
    &:hover {
      svg {
        fill: #405de6;
      }
    }
  }
  
  .pinterest {
    &:hover {
      svg {
        fill: #bd081c;
      }
    }
  }
  
  .twitter {
    &:hover {
      svg {
        fill: #1da1f2;
      }
    }
  }
  
  .youtube {
    &:hover {
      svg {
        fill: #ff0000;
      }
    }
  }
}

.home {
  .main {
    display: flex;
    height: 100vh;
    flex-direction: column;
    
    .content-wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      background-color: #000;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
  }
  
  .slide-caption {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    max-width: 490px;
    padding: 15px;
    background-color: rgba(0,0,0,.6);
    
    @media (min-width: 600px) {
      font-size: 30px;
      padding: 15px 100px 15px 20px;
    }
    
    @media (min-width: 992px) {
      position: absolute;
      right: 0;
      top: 55%;
    }
    
    @media (min-width: 1600px) {
      font-size: 38px;
    }
  }
  
  .slick-dots {
    @media (max-width: 991px) {
      bottom: 0;
    }
  }
  
  .slick-prev, .slick-next {
    @media (max-width: 991px) {
      top: 35%;
    }
  }
}

.slick-list, .slick-track, .slick-slide > div {
  height: 100%;
}

.contact-page {
  .content-wrapper {
    display: flex;
  }
  
  .contact-wrapper, .content {
    width: 100%;
    display: flex;
  }
  
  .contact-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 30px 30px;
    
    @media (min-width: 768px) {
      justify-content: flex-end;
      padding: 80px 50px 80px 50px;
    }
  }
}

@import "../variables";

.materials-panel {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(35, 31, 32, 0.31);
  z-index: 22;
  
  h3 {
    color: #231f20;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: -0.28px;
    margin: 0;
    white-space: nowrap;
    
    @media (min-width: 1366px) {
      font-size: 19px;
    }
    
    @media (max-width: $panelMobile) {
      width: 100%;
    }
  }
  
  &.desktop-visible {
    display: flex;
    justify-content: space-between;
  
    @media (max-width: $panelMobile) {
      display: none;
    }
  }
  
  &.desktop-hidden {
    @media (min-width: $panelDesktop) {
      display: none!important;
    }
  }
}

.material-block {
  &.flow {
    display: flex;
    flex-direction: column;

    h3 {
      margin-right: 10px;
    }

    .wrap {
      display: inline-flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 80px;
      flex-grow: 0;
      margin: 0 -5px;
    }

    .material-item {
      flex: 0 1 20px;
    }

  }
  
  .wrap {
    display: block;
    margin-left: -5px;
    margin-right: -5px;
  }
  
  &--beech {
    .material-item {
      .tooltip {
        transform: translate3d(0, -102%, 0);
      }
    }
  }
  
  &--railings {
  }
  
  &--glass {
  }
  
  &--options {
    display: inline-block;
    
    h3 {
      margin-right: 10px;
    }
    
    .wrap {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-left: 0;
      margin-right: 0;
    }
    
    .material-item {
      width: 20px;
      height: 20px;
      
      img {
        width: 20px;
        height: 20px;
      }
  
      .tooltip {
        transform: translate3d(-90%, -102%, 0);
      }
    }
  }
}

.divider {
  width: 1px;
  height: 100%;
  background: #231f20;
  flex: 1 0 auto;
  max-width: 1px;
  margin-left: 15px;
  margin-right: 15px;
}

.material-item {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 5px;
  border: 1px solid #c5c5c5;
  background-size: cover;
  cursor: pointer;
  
  img {
    display: block;
    width: 30px;
    height: 30px;
  }
  
  @media (min-width: $panelDesktop) {
    //width: 20px;
    //height: 20px;
    
    img {
      display: block;
      //width: 20px;
      //height: 20px;
    }
  }
}

.material-options {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  
  > span {
    color: #231f20;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
}

.material-item, .material-options {
  position: relative;
  
  .tooltip {
    position: absolute;
    display: none;
    padding: 3px;
    top: 0;
    left: 0;
    opacity: 0;
    border: 1px solid #000;
    background-color: #fff;
    visibility: hidden;
    transform: translate3d(-50px, -102%, 0);
    transition: .4s ease-in-out;
    z-index: 100;
  
    @media (min-width: 768px) {
      transform: translate3d(-100px, -102%, 0);
    }
    
    p {
      font-size: 16px;
      margin-top: 3px;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      
      @media (min-width: 1366px) {
        font-size: 18px;
      }
    }
    
    span {
      display: block;
      width: 100px;
      height: 100px;
      overflow: hidden;
      background-size: cover;
      margin-left: auto;
      margin-right: auto;
      
      @media (min-width: 768px) {
        width: 200px;
        height: 200px;
      }
    }
  }
  
  &:hover {
    //@media (min-width: 1340px) {
      .tooltip {
        position: absolute;
        display: inline-block;
        opacity: 1;
        visibility: visible;
      }
    //}
  }
}
